@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import 'tom-select/dist/css/tom-select.css';
@import 'tom-select.css';

@layer components {
    label.has-errors {
        @apply text-red-600;
    }
    input.has-errors {
        @apply border-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500;
    }
    .section {
        @apply text-gray-600 font-medium;
    }
    .description {
        @apply text-gray-700 font-light py-2 mb-5 text-sm italic;
    }
    .important {
        @apply bg-yellow-50;
    }
    .new {
        @apply text-green-600 bg-green-100 hover:bg-emerald-200 hover:text-emerald-600 shadow;
    }
    .edit {
        @apply bg-blue-400 hover:bg-blue-600;
    }
    .delete {
        @apply bg-red-300 hover:bg-red-600;
    }
    .form-label {
        @apply form-label-inline block;
    }
    .form-label-inline {
        @apply text-sm font-medium text-gray-700;
    }
    .form-field {
        @apply mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-wiab-blue sm:text-sm placeholder-gray-300;
    }
    .form-field-checkbox-no-block {
        @apply mt-1 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-wiab-blue sm:text-sm;

    }
    .form-field-checkbox {
        @apply form-field-checkbox-no-block block;
    }
    .tbody-cell {
        @apply table-cell text-sm text-gray-700 pl-1 pb-1;
    }
    .dropdown-menu-item {
        @apply block px-4 py-2 text-sm text-gray-700 hover:bg-wiab-blue hover:text-white;
    }

    .mobile-menu-sub-item {
        @apply block px-4 py-2 text-gray-700 hover:bg-wiab-blue hover:text-white;
    }

    .link {
        @apply text-wiab-blue hover:underline;
    }

    .toggle-switch {
        @apply shrink-0 w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500;
    }

    /* The styling below is custom in that we require the :checked Pseudo class, which Tailwind doesn't offer out of the box. However the styling (border color and box shadow) the below applies is native Tailwind. */
    .toggle-label input:checked + label {
        border-color: #54bbd5;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    }
}